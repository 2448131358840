<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <!-- 搜索 -->
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >文件名：
              <el-input
                class="search_input"
                v-model="parameter.documentationName"
                clearable
                placeholder="请输入文件名" />
            </span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)">
              搜索
            </el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <!-- 新增标签按钮-->
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <!-- 表格展示 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading">
            <el-table-column align="center" width="50" prop="index" label="序号">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="documentationName" label="文件名" />
            <el-table-column prop="departFiles" label="文件路径" align="center">
              <template slot-scope="scope">
                <div>
                  <a
                    target="_blank"
                    style="text-decoration: revert; color: #409eff"
                    :href="`${filepath}${scope.row.documentationPath}`">
                    {{ scope.row.documentationPath }}
                  </a>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="remark" label="备注" />
            <el-table-column prop="" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-popconfirm
                  icon="el-icon-info"
                  title="确定删除该文件吗？"
                  @confirm="del(scope.row)"
                  @onConfirm="del(scope.row)">
                  <el-button class="btn_danger" size="mini" slot="reference" type="text">
                    删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize" />
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
      <!-- 新增文件 -->
      <el-dialog title="上传文件" :visible.sync="upload.open" width="400px" append-to-body>
        <el-upload
          ref="upload"
          :limit="1"
          action=""
          :http-request="httpRequest"
          :disabled="upload.isUploading"
          :on-progress="handleFileUploadProgress"
          :on-success="handleFileSuccess"
          :auto-upload="false"
          drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip">
            <span class="remark"
              >备注：
              <el-input v-model="remark" clearable placeholder="请输入该文件的备注" size="mini" />
            </span>
          </div>
        </el-upload>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitFileForm" :loading="loading">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    UploadResume: () => import('@/components/upload/UploadResume.vue'),
  },
  data() {
    return {
      parameter: {
        //获取表格
        documentationName: '',
        pageNow: 1,
        pageSize: 10,
      },
      pageSize: 0, //当前页面
      total: 0, //总条数
      tableData: [], //人员列表
      loading: false,
      filepath: process.env.VUE_APP_FILEPATH,
      upload: {
        open: false, // 是否显示弹出层
        isUploading: false, // 是否禁用上传
      },
      remark: '', //上传文件的备注
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData() //获取列表
  },

  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    // 获取列表
    getData() {
      this.loading = true
      this.$api.user
        .documentList(this.parameter)
        .then(res => {
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data.records
            this.total = res.data.total
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.documentationName = ''
      this.pageChangeHandler(1)
    },

    // 删除
    del(row) {
      this.loading = true
      this.$api.user
        .deleteFiles(row.id)
        .then(res => {
          this.loading = false
          this.$message.success('删除成功！')
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 导入按钮操作 */
    add() {
      this.remark = ''
    },

    // 取消新增
    cancel() {
      this.upload.open = false
      this.remark = ''
      this.$refs.upload.clearFiles() // 重置上传表单
    },

    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true
    },

    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false
      this.upload.isUploading = false
    },

    // 自定义上传行为,覆盖原组件行为
    httpRequest(params) {
      this.loading = true
      var files = params.file
      var form = new FormData()
      form.append('file', files)
      form.append('type', 'knowledge')
      this.$api.common
        .uploadFile(form)
        .then(res => {
          var temp = {
            documentationName: res.data.fileName,
            documentationPath: res.data.filePath,
            documentationType: 'knowledge',
            remark: this.remark,
          }
          this.$api.user
            .addFiles(temp)
            .then(() => {
              this.$message.success('上传成功！')
              this.loading = false
              this.upload.open = false
              this.getData()
              this.remark = ''
              this.$refs.upload.clearFiles() // 重置上传表单
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit()
    },

    // 表格行数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.parameter.pageNow = 1
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList_operation {
  margin-top: 20px;
}

.search_input {
  width: 165px;
  margin-right: 10px;
}

.btn_danger {
  padding-left: 5px;
}

.mainList_operation {
  margin-top: 0px;
}

/deep/ .remark .el-input {
  width: 86%;
}
</style>
